<template>
  <div>
    <v-sheet>
      <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="320"
        >
          <v-card>
            <div style="text-align: center;font-size: 15px">
              <div style="padding: 10px">
                <p>
                  정말로 삭제하시겠습니까?
                </p>
              </div>
            </div>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="green darken-1"
                  text
                  @click="confirm"
              >
                확인
              </v-btn>
              <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false"
              >
                취소
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>

const {isEmpty} = require("@/lib/CommUtil");


export default {
  name: "Confirm",
  data () {
    return {
      dialog: false,
      resultCallback: null,
    }
  },
  methods:{
    open(callback){
      this.dialog=true
      this.resultCallback= callback
    },
    confirm(){
      this.dialog=false
      this.resultCallback(true)
    },
    close(){
      this.dialog=false
      this.resultCallback(false)
    }
  }

}
</script>

<style scoped>

</style>