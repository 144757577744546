<template>
  <div>
    <v-sheet class="mx-auto text-center"  style="background:#dabfbe;
    position:relative;width: 100%;max-width: 1920px;min-height: 560px;height: 100%"
             v-if="$vuetify.breakpoint.lgAndUp"
    >
      <v-carousel
          hide-delimiters
          class="test"
          style="width: 100%;display: inline-block;max-width: 800px;"
          cycle
      >
        <v-carousel-item class="mx-auto">
          <v-img width="556" height="556" contain :src="require('@/assets/introduce/shop_pc.png')"></v-img>
        </v-carousel-item>

        <v-carousel-item class="mx-auto">
          <v-img width="200" height="556" contain :src="require('@/assets/introduce/shop_mobile2.png')"></v-img>
        </v-carousel-item>

        <v-carousel-item class="mx-auto">
          <v-img width="200" height="556" contain :src="require('@/assets/introduce/shop_mobile3.png')">
          </v-img>
        </v-carousel-item>
      </v-carousel>
      <div style="padding-top:200px;padding-bottom:100px; display: inline-block;vertical-align: top" >
        <p class="text-h3 font-weight-bold">나만의 무료 반응형 쇼핑몰</p>
        <p class="text-h6">
          전문 지식 없이도 쉽고 빠르게<br/>
          <font class="font-weight-bold">나만의 반응형 쇼핑몰</font>을 별도 비용 없이 직접 만들 수 있습니다
        </p>
      </div>
    </v-sheet>
    <v-sheet class="mx-auto text-center"  style="background:#dabfbe;
    position:relative;width: 100%;max-width: 1920px;min-height: 560px;height: 100%"
           v-else
    >
      <v-carousel
          hide-delimiters
          class="test"
          style="width: 100%;display: inline-block;max-width: 800px;"
          cycle
      >
        <v-carousel-item class="mx-auto">
          <v-img width="556" height="556" contain :src="require('@/assets/introduce/shop_pc.png')"></v-img>
        </v-carousel-item>

        <v-carousel-item class="mx-auto">
          <v-img width="200" height="556" contain :src="require('@/assets/introduce/shop_mobile2.png')"></v-img>
        </v-carousel-item>

        <v-carousel-item class="mx-auto">
          <v-img width="200" height="556" contain :src="require('@/assets/introduce/shop_mobile3.png')">
          </v-img>
        </v-carousel-item>
      </v-carousel>
      <div style="padding-top:100px;padding-bottom:100px; display: inline-block;vertical-align: top" >
        <p class="text-h3 font-weight-bold">나만의 무료 반응형 쇼핑몰</p>
        <p class="text-h6">
          전문 지식 없이도 쉽고 빠르게<br/>
          <font class="font-weight-bold">나만의 반응형 쇼핑몰</font>을 별도 비용 없이 직접 만들 수 있습니다
        </p>
      </div>
    </v-sheet>
  </div>


</template>

<script>
export default {
  name: "MainFunctionView",
  created() {
    console.log('MainFunctionView created')
  }
}
</script>

<style scoped>

</style>