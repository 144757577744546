<template>
  <div>
    <v-sheet height="383" style="position:relative;"
             v-if="$vuetify.breakpoint.lgAndUp"
    >
      <v-img height="383" :src="require('@/assets/introduce/1.png')"></v-img>
      <v-sheet color="transparent" style="position:absolute; width:1274px; top:50%; left:40%; transform: translate(-50%, -50%);">
        <p class="text-h3 white--text">SNS 마켓 판매</p>
        <p class="text-h6 white--text">
          다양한 SNS에서 <font class="font-weight-bold">별도의 쇼핑몰을 만들지 않아도</font><br/>
          주문서와 배송관리를 사용할 수 있는 결제 서비스입니다.<br/>
          <font class="font-weight-bold">SNS마켓의 단점인 제한적 결제수단! 이제 주문서 링크를 SNS에 게시하세요.</font><br/>
          카드는 물론 다양한 결제수단을 지원합니다.
        </p>
      </v-sheet>
      <v-img style="position:absolute;left: 34%;top: -30px;" height="500" contain :src="require('@/assets/introduce/order_sheet.png')"></v-img>
    </v-sheet>
    <v-sheet height="383" style="position:relative;"
             v-else
    >
      <v-img height="383" :src="require('@/assets/introduce/1.png')"></v-img>
      <v-sheet color="transparent" style="position:absolute; width:100%; top:20%; left:30px;">
        <p class="text-h4 white--text">SNS 마켓 판매</p>
        <p class="text-h7 white--text">
          다양한 SNS에서 <font class="font-weight-bold">별도의 쇼핑몰을 만들지 않아도</font><br/>
          주문서와 배송관리를 사용할 수 있는 결제 서비스입니다.<br/>
          <font class="font-weight-bold">SNS마켓의 단점인 제한적 결제수단! <br/>이제 주문서 링크를 SNS에 게시하세요.</font><br/>
          카드는 물론 다양한 결제수단을 지원합니다.
        </p>
      </v-sheet>
      <v-img style="position:absolute;top: -30px;left: 450px" height="500" width="255" :src="require('@/assets/introduce/order_sheet.png')"></v-img>
    </v-sheet>
  </div>
</template>

<script>
export default {
  name: "MainFunctionView2",
  created() {
  }
}
</script>

<style scoped>

</style>